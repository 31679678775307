import React from "react";
import { Tooltip } from "../tooltips/tooltip";
import SmFollow from "./sm-follow";
import { useIntl } from "../../i18n";

export default function FooterLogos() {
  const intl = useIntl()

  const icons = config.footer.icons;
  const business = config.footer.business;
  const imgRoot = window.config.projectRoot + "images/pro/footer/";

  return (
    <>
      <section className="section section-padding page-footer-logos">

        <ul className="list-inline">
          {icons.map((icon, i) => (
            <li key={i}>
              <div className="footer-logo" key={i}>
                <Tooltip label={icon.title}>
                  <a
                    href={icon.url}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="footer-logo-link avoid-external-icon"
                  >
                    <img
                      className="footer-logo-img"
                      alt={icon.title}
                      src={imgRoot + icon.img}
                    />
                  </a>
                </Tooltip>
                {icon.region && <SmFollow region={icon.region} />}
              </div>
            </li>
          ))}
        </ul>
      </section>
      <section className="section section-padding page-footer-logos">
        <h2>
          {intl.formatMessage({
            id: "footer:collab:title"
          })}
        </h2>
        <ul className="list-inline">
          {business.map((icon, i) => (
            <li key={i}>
              <Tooltip label={icon.title}>
                <a
                  href={icon.url}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="avoid-external-icon tooltip"
                >
                  <img
                    alt={icon.title}
                    title={icon.title}
                    src={imgRoot + icon.img}
                  />
                </a>
              </Tooltip>
            </li>
          ))}
        </ul>
      </section>

    </>
  );
}
